<script setup>

</script>

<template>
  <SubContentHeader centered>Jak skorzystać z oferty</SubContentHeader>
  <ContentDescription glued>Proces nabycia samochodu w VEHIS jest prosty i przyjazny</ContentDescription>
  <ContentSteps>
    <ContentStep>Wybierz samochód z ponad <b>3000</b> dostępnych ofert.</ContentStep>
    <ContentStep><b>Wspólnie skalkulujemy</b> ofertę<br/>finansowania i ubezpieczenia.</ContentStep>
    <ContentStep>Finansowanie przyznamy<br/><b>w 15 minut</b> od złożenia wniosku.</ContentStep>
    <ContentStep selected>Samochód odbierzesz nawet<br/><b>w 24h</b> od podpisania umowy.</ContentStep>
  </ContentSteps>
</template>
